.fixedHeader {

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  tbody {
    display: block;
  }
}

.table {
  tbody tr {
    position: relative;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.tdHidden {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}